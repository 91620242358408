import React from "react"
import PropTypes from "prop-types"
import ReactMarkdownEditor from "./ReactMarkdownEditor"
import { Converter } from "showdown"
import marked from "marked"
import axios from "axios"

class GigDescriptionEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.description.description,
      ...props.description,
      markdown: this.translateMarkdown(props.markdown),
      url: props.url,
      method: props.method,
      token: props.token
    }
    this.converter = new Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    })

    console.log(props.description);
  }

  translateMarkdown(value) {
    return {
      __html: marked(value)
    }
  }

  handleTitleChange = (event) => {
    this.setState({
      title: event.target.value
    })
  }

  handleBylineChange = (event) => {
    this.setState({
      byline: event.target.value
    })
  }

  handleValueChange = (value) => {
    this.setState({
      value,
      markdown: this.translateMarkdown(value)
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { url, token, method } = this.props
    console.log("Submitting!")
    axios({
      method: method,
      url: url,
      data: {
        gig_description: {
          description: this.state.value,
          title: this.state.title,
          byline: this.state.byline
        },
        authenticity_token: token
      }
    })
  }

  render () {
    const { byline, markdown, title, value } = this.state
    return (
      <React.Fragment>
        <div className="control">
          <input className="input" type="text" placeholder="Toimeksiannon otsikko" defaultValue={title} onChange={this.handleTitleChange} />
        </div>
        <div className="control">
          <input className="input" type="text" placeholder="Toimeksiannon lyhyt kuvaus" defaultValue={byline} onChange={this.handleBylineChange} />
        </div>
        <ReactMarkdownEditor
          onChange={this.handleValueChange}
          value={value}
          markdown={markdown}
        />
        <button onClick={this.handleSubmit}>Save</button>
      </React.Fragment>
    );
  }
}

export default GigDescriptionEditor
