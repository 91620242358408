import React from "react"
import PropTypes from "prop-types"
import GigDescriptionTextArea from "./GigDescriptionTextArea";
import GigDescriptionRenderer from "./GigDescriptionRenderer";

class ReactMarkdownEditor extends React.Component {
  constructor(props) {
    super(props)
  }

  handleTextChange = (value) => {
    const { onChange } = this.props
    onChange(value)
  }

  render () {
    const {
      value,
      markdown
    } = this.props

    return (
      <React.Fragment>
        <div className="columns">
          <div className="column">
            <GigDescriptionTextArea
              onChange={this.handleTextChange}
              value={value}
            />
          </div>
          <div className="column">
            <GigDescriptionRenderer
              markdown={markdown}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReactMarkdownEditor
