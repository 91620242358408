import React from "react"
import PropTypes from "prop-types"
class GigDescriptionTextArea extends React.Component {
  handleTextChange = (value) => {
    const { onChange } = this.props
    onChange(event.target.value)
  }

  render () {
    const {
      value
    } = this.props

    return (
      <React.Fragment>
        <textarea
          className="textarea"
          rows="30"
          onChange={this.handleTextChange}
          value={value}
        >
        </textarea>
      </React.Fragment>
    );
  }
}

export default GigDescriptionTextArea
