import React from "react"
import PropTypes from "prop-types"

class GigDescriptionRenderer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      markdown: props.markdown
    }
  }

  render () {
    const { markdown } = this.props

    let content

    content = <div
        className="preview-content"
        dangerouslySetInnerHTML={markdown}
      />

    return (
      <React.Fragment>
        <div className="description-preview content">{content}</div>
      </React.Fragment>
    );
  }
}

export default GigDescriptionRenderer